import React, { useEffect, useState } from "react";
import Loader from "../../general/Loader";
import "./style.css";

const selectCamera = view => {
    const cameraTarget = document.querySelector(`#${view}-camera`);
    // Set the active camera to the selected planet
    cameraTarget.setAttribute("camera", "active", true);
    [...document.querySelectorAll(".infopanel")].map(e => {
        e.style.display = "none";
    });
    const infoTarget = document.querySelector(`#${view}-info`);
    if (infoTarget) {
        infoTarget.style.display = "block";
    }
};

const SolarSystem = props => {
    const [isInit, setIsInit] = useState(null);
    useEffect(() => {
        if (isInit === null) {
            setTimeout(() => setIsInit(true), 1000);
        }
    });
    if (isInit === null) {
        return <Loader />;
    }

    return (
        <div>
            <a-scene
                id="solarsystem-experience"
                vr-mode-ui="enabled: false"
                background="color: #222"
            >
                <a-assets>
                    <img id="sun" src="/experiences/solarsystem/img/sun.jpg" />
                    <img
                        id="sky"
                        src="/experiences/solarsystem/img/stsci-h-p2001a-m-2000x1500_0.png"
                    />
                    <img
                        id="rings"
                        src="/experiences/solarsystem/img/ring-system.png"
                    />
                </a-assets>

                <a-sky
                    src="#sky"
                    material="height: 1024; width: 1024"
                    geometry="radius: 5000; segmentsWidth: 64; segmentsHeight: 64"
                    id="stars"
                ></a-sky>

                <a-entity id="rig" position="">
                    <a-entity
                        id="sun-camera"
                        camera="active: true"
                        position="0 0 9"
                        look-controls=""
                        wasd-controls="acceleration: 125; fly: true"
                        rotation=""
                    ></a-entity>
                </a-entity>

                <a-entity
                    id="mouseCursor"
                    cursor="rayOrigin: mouse"
                    raycaster="objects: .clickable; useWorldCoordinates: true"
                ></a-entity>

                <a-entity
                    id="solar-system"
                    animation__startup="dur: 5000;  easing:  easeOutQuad;  from:  0 0 -50;  property:  position;  startEvents:  ;  pauseEvents:  ;  resumeEvents:  ;  to:   0 0 0 "
                >
                    <a-entity id="sun" rotation="0 0 0" scale="1 1 1">
                        <a-entity
                            light="castShadow: true; groundColor: #fff; distance: 0; type: point;"
                            id="sunlight"
                        ></a-entity>
                        <a-sphere
                            id="sun-orb"
                            geometry="segmentsHeight: 36"
                            src="#sun"
                            animation="property: rotation; from: 0 0 0; to: 0 360 0; dur: 60000; easing: linear; loop: true"
                            class="clickable"
                            material="shader: flat"
                            rotation=""
                        ></a-sphere>
                    </a-entity>

                    <a-entity
                        id="mercury"
                        animation="property:rotation; from: 0 140 0; to: 0 500 0; dur: 144920; easing: linear; loop: true"
                    >
                        <a-sphere
                            id="mercury-planet"
                            position="0 0 36"
                            geometry="radius: 1.5"
                            src="/experiences/solarsystem/img/mercury.jpg"
                            animation="property: rotation; from: 0 0 0; to: 0 360 0; dur: 140880; easing: linear; loop: true"
                        ></a-sphere>

                        <a-entity
                            id="mercury-camera-rig"
                            position="-10 0 36"
                            rotation="0 -90 0"
                        >
                            <a-entity
                                id="mercury-camera"
                                camera="active: false"
                            ></a-entity>
                        </a-entity>
                    </a-entity>

                    <a-entity
                        id="venus"
                        animation="property:rotation; from: 0 70 0; to: 0 430 0; dur: 375000; fill: forwards; easing: linear; loop: true"
                    >
                        <a-sphere
                            id="venus=planet"
                            position="0 0 67"
                            geometry="radius: 3.7"
                            src="/experiences/solarsystem/img/venus.jpg"
                            animation="property: rotation; from: 0 0 0; to: 0 360 0; dur: 58320; easing: linear; loop: true"
                        ></a-sphere>

                        <a-entity
                            id="venus-camera-rig"
                            position="-15 0 67"
                            rotation="0 -90 0"
                        >
                            <a-entity
                                id="venus-camera"
                                camera="active: false"
                            ></a-entity>
                        </a-entity>
                    </a-entity>

                    <a-entity
                        id="earth"
                        animation="property:rotation; from: 0 210 0; to: 0 570 0; dur: 600000; fill: forwards; easing: linear; loop: true"
                    >
                        <a-entity
                            id="earth-system"
                            position="0 0 93"
                            animation="property:rotation; from: 0 210 0; to: 0 570 0; dur: 15200; fill: forwards; easing: linear; loop: true"
                        >
                            <a-sphere
                                id="earth-planet"
                                geometry="radius: 3.9"
                                src="/experiences/solarsystem/img/earth.jpg"
                                animation="property:rotation; from:0 0 0; to: 0 360 0; dur: 24000; easing: linear; loop: true"
                            ></a-sphere>

                            <a-sphere
                                id="moon"
                                position="0 0 23"
                                geometry="radius: 1.2"
                                src="/experiences/solarsystem/img/moon.jpg"
                            ></a-sphere>
                        </a-entity>
                        <a-entity
                            id="earth-camera-rig"
                            position="-15 0 93"
                            rotation="0 -90 0"
                        >
                            <a-entity
                                id="earth-camera"
                                camera="active: false"
                                data-aframe-inspector-original-camera=""
                            ></a-entity>
                        </a-entity>
                    </a-entity>

                    <a-entity
                        id="mars"
                        animation="property:rotation; from: 0 180 0; to: 0 540 0; dur: 112504; fill: forwards; easing: linear; loop: true"
                    >
                        <a-sphere
                            id="mars-planet"
                            position="0 0 141"
                            geometry="radius: 2.1"
                            src="/experiences/solarsystem/img/mars.jpg"
                            animation="property: rotation; from: 0 180 0; to: 0 540 0; dur: 24600; easing: linear; loop: true"
                        ></a-sphere>
                        <a-entity
                            id="mars-camera-rig"
                            position="-10 0 141"
                            rotation="0 -90 0"
                        >
                            <a-entity
                                id="mars-camera"
                                camera="active: false"
                            ></a-entity>
                        </a-entity>
                    </a-entity>

                    <a-entity
                        id="jupiter"
                        animation="property:rotation; from:0 90 0; to: 0 450 0; dur: 711600; fill: forwards; easing: linear; loop: true"
                    >
                        <a-sphere
                            id="jupiter-planet"
                            position="0 0 240"
                            geometry="radius: 4.4"
                            src="/experiences/solarsystem/img/jupiter.jpg"
                            animation="property: rotation; from: 0 0 0; to: 0 360 0; dur: 9840; easing: linear; loop: true"
                        ></a-sphere>

                        <a-entity
                            id="jupiter-camera-rig"
                            position="-15 0 240"
                            rotation="0 -90 0"
                        >
                            <a-entity
                                id="jupiter-camera"
                                camera="active: false"
                            ></a-entity>
                        </a-entity>
                    </a-entity>

                    <a-entity
                        id="saturn"
                        animation="property:rotation; from:0 220 0; to: 0 580 0; dur: 1767360;  fill: forwards; easing: linear; loop: true"
                    >
                        <a-sphere
                            id="saturn-planet"
                            position="0 0 420"
                            geometry="radius: 3.73"
                            src="/experiences/solarsystem/img/saturn.jpg"
                            animation="property: rotation; from: 0 0 0; to: 0 360 0; dur: 10200; easing: linear; loop: true"
                        ></a-sphere>
                        <a-entity
                            id="saturn-ring-1"
                            position="0 0 420"
                            geometry="primitive: ring; radiusInner: 7; radiusOuter: 7.5; segmentsTheta: 128"
                            rotation="80 0 0"
                            material="opacity: 0.51; side: double; blending: additive; emissive: #222222"
                        ></a-entity>

                        <a-entity
                            id="saturn-ring-2"
                            position="0 0 420"
                            geometry="primitive: ring; radiusInner: 8; radiusOuter: 9; segmentsTheta: 128"
                            rotation="80 0 0"
                            material="opacity: 0.51; side: double; blending: additive; emissive: #222222"
                        ></a-entity>
                        <a-entity
                            id="saturn-ring-2"
                            position="0 0 420"
                            geometry="primitive: ring; radiusInner: 9.5; radiusOuter: 10; segmentsTheta: 128"
                            rotation="80 0 0"
                            material="opacity: 0.51; side: double; blending: additive; emissive: #222222"
                        ></a-entity>

                        <a-entity
                            id="saturn-camera-rig"
                            position="0 3 395"
                            rotation="-10 -180 0"
                        >
                            <a-entity
                                id="saturn-camera"
                                camera="active: false"
                            ></a-entity>
                        </a-entity>
                    </a-entity>

                    <a-entity
                        id="uranus"
                        animation="property:rotation; from:0 80 0; to: 0 440 0; dur: 5040000; fill: forwards; easing: linear; loop: true"
                    >
                        <a-sphere
                            id="uranus-planet"
                            position="0 0 600"
                            geometry="radius: 1.63"
                            src="/experiences/solarsystem/img/uranus.jpg"
                            animation="property:rotation; from:0 0 0; to: 0 360 0; dur: 1790; easing: linear; loop: true"
                        ></a-sphere>
                        <a-image
                            id="uranus-rings"
                            src="#rings"
                            position="0 0 600"
                            scale="10 10 10"
                            rotation="0 180 0"
                            material=""
                            geometry="segmentsHeight: 20; segmentsWidth: 20"
                        ></a-image>

                        <a-entity
                            id="uranus-camera-rig"
                            position="0 0 590"
                            rotation="0 -180 0"
                        >
                            <a-entity
                                id="uranus-camera"
                                camera="active: false"
                            ></a-entity>
                        </a-entity>
                    </a-entity>

                    <a-entity
                        id="neptune"
                        animation="property:rotation; from: 0 60 0; to: 0 420 0; dur: 10440000; fill: forwards; easing: linear;loop: true"
                    >
                        <a-sphere
                            id="neptune-sphere"
                            position="0 0 700"
                            geometry="radius: 1.51"
                            src="/experiences/solarsystem/img/neptune.jpg"
                            animation="property:rotation; from: 0 0 0; to: 0 360 0; dur: 19100; loop: true; easing: linear"
                        ></a-sphere>

                        <a-entity
                            id="neptune-camera-rig"
                            position="-10 0 700"
                            rotation="0 -90 0"
                        >
                            <a-entity
                                id="neptune-camera"
                                camera="active: false"
                            ></a-entity>
                        </a-entity>
                    </a-entity>

                    <a-entity
                        id="pluto"
                        animation="property:rotation; from: 0 180 0; to: 0 540 0; dur: 14820000; fill: forwards; easing: linear; loop: true"
                    >
                        <a-sphere
                            position="0 0 800"
                            src="/experiences/solarsystem/img/pluto.jpg"
                            animation="property: rotation; from: 0 0 0; to: 0 360 0; dur: 15336; loop: true; easing: linear"
                            material=""
                            geometry="radius: 0.7"
                            id="pluto-sphere"
                        ></a-sphere>

                        <a-entity
                            id="pluto-camera-rig"
                            position="-5 0 800"
                            rotation="0 -90 0"
                        >
                            <a-entity
                                id="pluto-camera"
                                camera="active: false"
                            ></a-entity>
                        </a-entity>
                    </a-entity>
                </a-entity>

                <a-entity
                    id="ambient-light"
                    light="color: #BBB; type: ambient; intensity: 0"
                    visible="false"
                ></a-entity>
                <a-entity
                    id="directional-light"
                    light="intensity: 0; castShadow: true"
                    position="-0.5 1 1"
                    visible="false"
                ></a-entity>
            </a-scene>

            <div className="hud">
                <button onClick={() => selectCamera("sun")}>Sun</button>
                <button onClick={() => selectCamera("mercury")}>Mercury</button>
                <button onClick={() => selectCamera("venus")}>Venus</button>
                <button onClick={() => selectCamera("earth")}>Earth</button>
                <button onClick={() => selectCamera("mars")}>Mars</button>
                <button onClick={() => selectCamera("jupiter")}>Jupiter</button>
                <button onClick={() => selectCamera("saturn")}>Saturn</button>
                <button onClick={() => selectCamera("uranus")}>Uranus</button>
                <button onClick={() => selectCamera("neptune")}>Neptune</button>
                <button onClick={() => selectCamera("pluto")}>Pluto</button>
            </div>

            <div id="mercury-info" className="infopanel">
                <h2>Mercury</h2>
                <div className="infotext">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris at nibh condimentum, accumsan magna et, maximus
                        massa. Quisque ut diam ante. Sed commodo libero et
                        molestie cursus. Phasellus suscipit efficitur quam et
                        sollicitudin. Fusce commodo volutpat urna eget accumsan.
                        Etiam ac felis pulvinar, tincidunt nulla non, finibus
                        arcu. Pellentesque at metus dui. Cras nec metus ac nunc
                        vulputate posuere. Aliquam auctor risus sit amet felis
                        egestas porttitor. Fusce ac ante leo. Integer tempus
                        blandit risus, eget faucibus turpis ornare in. Duis
                        facilisis nisi eu quam elementum, vel aliquet dui
                        faucibus.
                    </p>
                    <p>
                        Ut ac efficitur est. Orci varius natoque penatibus et
                        magnis dis parturient montes, nascetur ridiculus mus.
                        Nunc aliquam sapien lacus, et interdum libero elementum
                        non. Quisque varius nisi eu rutrum ultrices. Curabitur
                        condimentum, magna vel bibendum lobortis, dui ligula
                        luctus ex, et accumsan lectus urna at purus. Fusce dui
                        sapien, sodales id efficitur blandit, tempor ac neque.
                        Nunc eu sodales magna, non molestie dolor. Donec
                        consectetur consectetur lorem, non hendrerit lacus
                        semper vel. Nunc ut nisl sit amet nunc iaculis
                        tincidunt. In vestibulum elit sed risus condimentum
                        pretium a eu lorem. In lobortis elit ut augue tempor
                        rutrum. Nulla ut leo blandit, ultricies arcu ac,
                        consequat erat. Proin non diam fringilla, mollis nibh
                        ultrices, congue enim. Ut enim leo, iaculis ac vulputate
                        vitae, vehicula in dolor.
                    </p>
                </div>
            </div>
            <div id="venus-info" className="infopanel">
                <h2>Venus</h2>
                <div className="infotext">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris at nibh condimentum, accumsan magna et, maximus
                        massa. Quisque ut diam ante. Sed commodo libero et
                        molestie cursus. Phasellus suscipit efficitur quam et
                        sollicitudin. Fusce commodo volutpat urna eget accumsan.
                        Etiam ac felis pulvinar, tincidunt nulla non, finibus
                        arcu. Pellentesque at metus dui. Cras nec metus ac nunc
                        vulputate posuere. Aliquam auctor risus sit amet felis
                        egestas porttitor. Fusce ac ante leo. Integer tempus
                        blandit risus, eget faucibus turpis ornare in. Duis
                        facilisis nisi eu quam elementum, vel aliquet dui
                        faucibus.
                    </p>
                    <p>
                        Ut ac efficitur est. Orci varius natoque penatibus et
                        magnis dis parturient montes, nascetur ridiculus mus.
                        Nunc aliquam sapien lacus, et interdum libero elementum
                        non. Quisque varius nisi eu rutrum ultrices. Curabitur
                        condimentum, magna vel bibendum lobortis, dui ligula
                        luctus ex, et accumsan lectus urna at purus. Fusce dui
                        sapien, sodales id efficitur blandit, tempor ac neque.
                        Nunc eu sodales magna, non molestie dolor. Donec
                        consectetur consectetur lorem, non hendrerit lacus
                        semper vel. Nunc ut nisl sit amet nunc iaculis
                        tincidunt. In vestibulum elit sed risus condimentum
                        pretium a eu lorem. In lobortis elit ut augue tempor
                        rutrum. Nulla ut leo blandit, ultricies arcu ac,
                        consequat erat. Proin non diam fringilla, mollis nibh
                        ultrices, congue enim. Ut enim leo, iaculis ac vulputate
                        vitae, vehicula in dolor.
                    </p>
                </div>
            </div>
            <div id="earth-info" className="infopanel">
                <h2>Earth</h2>
                <div className="infotext">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris at nibh condimentum, accumsan magna et, maximus
                        massa. Quisque ut diam ante. Sed commodo libero et
                        molestie cursus. Phasellus suscipit efficitur quam et
                        sollicitudin. Fusce commodo volutpat urna eget accumsan.
                        Etiam ac felis pulvinar, tincidunt nulla non, finibus
                        arcu. Pellentesque at metus dui. Cras nec metus ac nunc
                        vulputate posuere. Aliquam auctor risus sit amet felis
                        egestas porttitor. Fusce ac ante leo. Integer tempus
                        blandit risus, eget faucibus turpis ornare in. Duis
                        facilisis nisi eu quam elementum, vel aliquet dui
                        faucibus.
                    </p>
                    <p>
                        Ut ac efficitur est. Orci varius natoque penatibus et
                        magnis dis parturient montes, nascetur ridiculus mus.
                        Nunc aliquam sapien lacus, et interdum libero elementum
                        non. Quisque varius nisi eu rutrum ultrices. Curabitur
                        condimentum, magna vel bibendum lobortis, dui ligula
                        luctus ex, et accumsan lectus urna at purus. Fusce dui
                        sapien, sodales id efficitur blandit, tempor ac neque.
                        Nunc eu sodales magna, non molestie dolor. Donec
                        consectetur consectetur lorem, non hendrerit lacus
                        semper vel. Nunc ut nisl sit amet nunc iaculis
                        tincidunt. In vestibulum elit sed risus condimentum
                        pretium a eu lorem. In lobortis elit ut augue tempor
                        rutrum. Nulla ut leo blandit, ultricies arcu ac,
                        consequat erat. Proin non diam fringilla, mollis nibh
                        ultrices, congue enim. Ut enim leo, iaculis ac vulputate
                        vitae, vehicula in dolor.
                    </p>
                </div>
            </div>
            <div id="mars-info" className="infopanel">
                <h2>Mars</h2>
                <div className="infotext">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris at nibh condimentum, accumsan magna et, maximus
                        massa. Quisque ut diam ante. Sed commodo libero et
                        molestie cursus. Phasellus suscipit efficitur quam et
                        sollicitudin. Fusce commodo volutpat urna eget accumsan.
                        Etiam ac felis pulvinar, tincidunt nulla non, finibus
                        arcu. Pellentesque at metus dui. Cras nec metus ac nunc
                        vulputate posuere. Aliquam auctor risus sit amet felis
                        egestas porttitor. Fusce ac ante leo. Integer tempus
                        blandit risus, eget faucibus turpis ornare in. Duis
                        facilisis nisi eu quam elementum, vel aliquet dui
                        faucibus.
                    </p>
                    <p>
                        Ut ac efficitur est. Orci varius natoque penatibus et
                        magnis dis parturient montes, nascetur ridiculus mus.
                        Nunc aliquam sapien lacus, et interdum libero elementum
                        non. Quisque varius nisi eu rutrum ultrices. Curabitur
                        condimentum, magna vel bibendum lobortis, dui ligula
                        luctus ex, et accumsan lectus urna at purus. Fusce dui
                        sapien, sodales id efficitur blandit, tempor ac neque.
                        Nunc eu sodales magna, non molestie dolor. Donec
                        consectetur consectetur lorem, non hendrerit lacus
                        semper vel. Nunc ut nisl sit amet nunc iaculis
                        tincidunt. In vestibulum elit sed risus condimentum
                        pretium a eu lorem. In lobortis elit ut augue tempor
                        rutrum. Nulla ut leo blandit, ultricies arcu ac,
                        consequat erat. Proin non diam fringilla, mollis nibh
                        ultrices, congue enim. Ut enim leo, iaculis ac vulputate
                        vitae, vehicula in dolor.
                    </p>
                </div>
            </div>
            <div id="jupiter-info" className="infopanel">
                <h2>Jupiter</h2>
                <div className="infotext">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris at nibh condimentum, accumsan magna et, maximus
                        massa. Quisque ut diam ante. Sed commodo libero et
                        molestie cursus. Phasellus suscipit efficitur quam et
                        sollicitudin. Fusce commodo volutpat urna eget accumsan.
                        Etiam ac felis pulvinar, tincidunt nulla non, finibus
                        arcu. Pellentesque at metus dui. Cras nec metus ac nunc
                        vulputate posuere. Aliquam auctor risus sit amet felis
                        egestas porttitor. Fusce ac ante leo. Integer tempus
                        blandit risus, eget faucibus turpis ornare in. Duis
                        facilisis nisi eu quam elementum, vel aliquet dui
                        faucibus.
                    </p>
                    <p>
                        Ut ac efficitur est. Orci varius natoque penatibus et
                        magnis dis parturient montes, nascetur ridiculus mus.
                        Nunc aliquam sapien lacus, et interdum libero elementum
                        non. Quisque varius nisi eu rutrum ultrices. Curabitur
                        condimentum, magna vel bibendum lobortis, dui ligula
                        luctus ex, et accumsan lectus urna at purus. Fusce dui
                        sapien, sodales id efficitur blandit, tempor ac neque.
                        Nunc eu sodales magna, non molestie dolor. Donec
                        consectetur consectetur lorem, non hendrerit lacus
                        semper vel. Nunc ut nisl sit amet nunc iaculis
                        tincidunt. In vestibulum elit sed risus condimentum
                        pretium a eu lorem. In lobortis elit ut augue tempor
                        rutrum. Nulla ut leo blandit, ultricies arcu ac,
                        consequat erat. Proin non diam fringilla, mollis nibh
                        ultrices, congue enim. Ut enim leo, iaculis ac vulputate
                        vitae, vehicula in dolor.
                    </p>
                </div>
            </div>
            <div id="saturn-info" className="infopanel">
                <h2>Saturn</h2>
                <div className="infotext">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris at nibh condimentum, accumsan magna et, maximus
                        massa. Quisque ut diam ante. Sed commodo libero et
                        molestie cursus. Phasellus suscipit efficitur quam et
                        sollicitudin. Fusce commodo volutpat urna eget accumsan.
                        Etiam ac felis pulvinar, tincidunt nulla non, finibus
                        arcu. Pellentesque at metus dui. Cras nec metus ac nunc
                        vulputate posuere. Aliquam auctor risus sit amet felis
                        egestas porttitor. Fusce ac ante leo. Integer tempus
                        blandit risus, eget faucibus turpis ornare in. Duis
                        facilisis nisi eu quam elementum, vel aliquet dui
                        faucibus.
                    </p>
                    <p>
                        Ut ac efficitur est. Orci varius natoque penatibus et
                        magnis dis parturient montes, nascetur ridiculus mus.
                        Nunc aliquam sapien lacus, et interdum libero elementum
                        non. Quisque varius nisi eu rutrum ultrices. Curabitur
                        condimentum, magna vel bibendum lobortis, dui ligula
                        luctus ex, et accumsan lectus urna at purus. Fusce dui
                        sapien, sodales id efficitur blandit, tempor ac neque.
                        Nunc eu sodales magna, non molestie dolor. Donec
                        consectetur consectetur lorem, non hendrerit lacus
                        semper vel. Nunc ut nisl sit amet nunc iaculis
                        tincidunt. In vestibulum elit sed risus condimentum
                        pretium a eu lorem. In lobortis elit ut augue tempor
                        rutrum. Nulla ut leo blandit, ultricies arcu ac,
                        consequat erat. Proin non diam fringilla, mollis nibh
                        ultrices, congue enim. Ut enim leo, iaculis ac vulputate
                        vitae, vehicula in dolor.
                    </p>
                </div>
            </div>
            <div id="uranus-info" className="infopanel">
                <h2>Uranus</h2>
                <div className="infotext">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris at nibh condimentum, accumsan magna et, maximus
                        massa. Quisque ut diam ante. Sed commodo libero et
                        molestie cursus. Phasellus suscipit efficitur quam et
                        sollicitudin. Fusce commodo volutpat urna eget accumsan.
                        Etiam ac felis pulvinar, tincidunt nulla non, finibus
                        arcu. Pellentesque at metus dui. Cras nec metus ac nunc
                        vulputate posuere. Aliquam auctor risus sit amet felis
                        egestas porttitor. Fusce ac ante leo. Integer tempus
                        blandit risus, eget faucibus turpis ornare in. Duis
                        facilisis nisi eu quam elementum, vel aliquet dui
                        faucibus.
                    </p>
                    <p>
                        Ut ac efficitur est. Orci varius natoque penatibus et
                        magnis dis parturient montes, nascetur ridiculus mus.
                        Nunc aliquam sapien lacus, et interdum libero elementum
                        non. Quisque varius nisi eu rutrum ultrices. Curabitur
                        condimentum, magna vel bibendum lobortis, dui ligula
                        luctus ex, et accumsan lectus urna at purus. Fusce dui
                        sapien, sodales id efficitur blandit, tempor ac neque.
                        Nunc eu sodales magna, non molestie dolor. Donec
                        consectetur consectetur lorem, non hendrerit lacus
                        semper vel. Nunc ut nisl sit amet nunc iaculis
                        tincidunt. In vestibulum elit sed risus condimentum
                        pretium a eu lorem. In lobortis elit ut augue tempor
                        rutrum. Nulla ut leo blandit, ultricies arcu ac,
                        consequat erat. Proin non diam fringilla, mollis nibh
                        ultrices, congue enim. Ut enim leo, iaculis ac vulputate
                        vitae, vehicula in dolor.
                    </p>
                </div>
            </div>
            <div id="neptune-info" className="infopanel">
                <h2>Neptune</h2>
                <div className="infotext">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris at nibh condimentum, accumsan magna et, maximus
                        massa. Quisque ut diam ante. Sed commodo libero et
                        molestie cursus. Phasellus suscipit efficitur quam et
                        sollicitudin. Fusce commodo volutpat urna eget accumsan.
                        Etiam ac felis pulvinar, tincidunt nulla non, finibus
                        arcu. Pellentesque at metus dui. Cras nec metus ac nunc
                        vulputate posuere. Aliquam auctor risus sit amet felis
                        egestas porttitor. Fusce ac ante leo. Integer tempus
                        blandit risus, eget faucibus turpis ornare in. Duis
                        facilisis nisi eu quam elementum, vel aliquet dui
                        faucibus.
                    </p>
                    <p>
                        Ut ac efficitur est. Orci varius natoque penatibus et
                        magnis dis parturient montes, nascetur ridiculus mus.
                        Nunc aliquam sapien lacus, et interdum libero elementum
                        non. Quisque varius nisi eu rutrum ultrices. Curabitur
                        condimentum, magna vel bibendum lobortis, dui ligula
                        luctus ex, et accumsan lectus urna at purus. Fusce dui
                        sapien, sodales id efficitur blandit, tempor ac neque.
                        Nunc eu sodales magna, non molestie dolor. Donec
                        consectetur consectetur lorem, non hendrerit lacus
                        semper vel. Nunc ut nisl sit amet nunc iaculis
                        tincidunt. In vestibulum elit sed risus condimentum
                        pretium a eu lorem. In lobortis elit ut augue tempor
                        rutrum. Nulla ut leo blandit, ultricies arcu ac,
                        consequat erat. Proin non diam fringilla, mollis nibh
                        ultrices, congue enim. Ut enim leo, iaculis ac vulputate
                        vitae, vehicula in dolor.
                    </p>
                </div>
            </div>
            <div id="pluto-info" className="infopanel">
                <h2>Pluto</h2>
                <div className="infotext">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris at nibh condimentum, accumsan magna et, maximus
                        massa. Quisque ut diam ante. Sed commodo libero et
                        molestie cursus. Phasellus suscipit efficitur quam et
                        sollicitudin. Fusce commodo volutpat urna eget accumsan.
                        Etiam ac felis pulvinar, tincidunt nulla non, finibus
                        arcu. Pellentesque at metus dui. Cras nec metus ac nunc
                        vulputate posuere. Aliquam auctor risus sit amet felis
                        egestas porttitor. Fusce ac ante leo. Integer tempus
                        blandit risus, eget faucibus turpis ornare in. Duis
                        facilisis nisi eu quam elementum, vel aliquet dui
                        faucibus.
                    </p>
                    <p>
                        Ut ac efficitur est. Orci varius natoque penatibus et
                        magnis dis parturient montes, nascetur ridiculus mus.
                        Nunc aliquam sapien lacus, et interdum libero elementum
                        non. Quisque varius nisi eu rutrum ultrices. Curabitur
                        condimentum, magna vel bibendum lobortis, dui ligula
                        luctus ex, et accumsan lectus urna at purus. Fusce dui
                        sapien, sodales id efficitur blandit, tempor ac neque.
                        Nunc eu sodales magna, non molestie dolor. Donec
                        consectetur consectetur lorem, non hendrerit lacus
                        semper vel. Nunc ut nisl sit amet nunc iaculis
                        tincidunt. In vestibulum elit sed risus condimentum
                        pretium a eu lorem. In lobortis elit ut augue tempor
                        rutrum. Nulla ut leo blandit, ultricies arcu ac,
                        consequat erat. Proin non diam fringilla, mollis nibh
                        ultrices, congue enim. Ut enim leo, iaculis ac vulputate
                        vitae, vehicula in dolor.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SolarSystem;
