import React from "react";
import Layout from "../../components/general/Layout";
import SolarSystem from "../../components/experiences/solarsystem";
import { Helmet } from "react-helmet";
import {
    EXPERIENCES,
    markExperienceAsVisited
} from "../../components/general/store";

const SolarSytemExperience = () => {
    markExperienceAsVisited(EXPERIENCES.SOLAR_SYSTEM.key);
    return (
        <Layout>
            <div>
                <Helmet>
                    <script src="https://unpkg.com/aframe-event-set-component@4.2.1/dist/aframe-event-set-component.min.js"></script>
                </Helmet>
                <SolarSystem />
            </div>
        </Layout>
    );
};

export default SolarSytemExperience;
